import {
  CREATE_REVIEW,
  GET_ORDER_REVIEW,
  LIST_AVERAGE_REVIEWS,
  LIST_REVIEWS,
  UPLOAD_REVIEW_MEDIAS
} from '@constants/review.constant'
import { medusaRequest } from '@lib/config'
import { AxiosResponse } from 'axios'
import { Media } from 'types/global'

export const listReviews = (
  productId: string,
  limit: number,
  currentPage: number
) =>
  medusaRequest(
    'GET',
    `${LIST_REVIEWS(
      productId
    )}?limit=${limit}&page=${currentPage}&approved=true`
  ).then((response) => response.data)

export const listAverageReviews = (productId: string) =>
  medusaRequest('GET', `${LIST_AVERAGE_REVIEWS(productId)}?approved=true`).then(
    (response) => response.data
  )

export const getReviewByOrder = (orderId: string) =>
  medusaRequest('GET', GET_ORDER_REVIEW(orderId))

export const uploadMedias = async (productId: string, mediaFiles: File[]) => {
  const formData = new FormData()
  mediaFiles.forEach((file) => {
    formData.append('files', file)
  })

  type MediaResponse = Media & { key: string }

  const response: AxiosResponse<{ result: MediaResponse[] }, any> =
    await medusaRequest('POST', UPLOAD_REVIEW_MEDIAS(productId), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  return response.data.result
}

export async function createReview({
  orderId,
  productId,
  content,
  stars,
  name,
  medias
}: {
  orderId: string
  productId: string
  content: string
  stars: number
  name: string
  medias?: Array<Media>
}) {
  const response = await medusaRequest('POST', CREATE_REVIEW(productId), {
    orderId,
    content,
    stars,
    name,
    medias
  })
  return response.data
}
