import { useEffect, useState } from 'react'
import { useCart } from 'medusa-react'
import {
  getPriceListProducts,
  listPriceList,
  getPriceList,
  ListProductsPriceListParams
} from '@lib/util/price-list-handler'
import { useQuery } from '@tanstack/react-query'
import { expiredDate } from '@lib/util/date'
import { IProductItem } from '@common/interface/product'
import { mapToProductPreview } from '@lib/hooks/use-layout-data'

export const useListPriceList = () => {
  const [listPriceLists, setListPriceLists] = useState<any[]>([])

  const {
    data: price_lists,
    isLoading,
    refetch
  } = useQuery(['list-price-list'], () => listPriceList())

  useEffect(() => {
    if (price_lists && price_lists.data?.price_lists?.length > 0) {
      const price_lists_filter = price_lists.data.price_lists.filter(
        (pl: any) =>
          pl.status === 'active' &&
          pl.is_flashsale &&
          pl.ends_at != null &&
          !expiredDate(pl.ends_at)
      )
      setListPriceLists(price_lists_filter)
    }
  }, [price_lists])

  return {
    listPriceLists,
    isLoading,
    refetch
  }
}

export const useGetPriceList = (id: string) => {
  return useQuery([`price-list-${id}`], () => getPriceList(id))
}

export const useListProductsPriceList = (
  priceListId: string,
  params: ListProductsPriceListParams
) => {
  const { cart } = useCart()
  const [listProductsPriceList, setListProductPriceList] = useState<
    IProductItem[]
  >([])

  const {
    data: price_list_products,
    refetch,
    isLoading
  } = useQuery(['price-list-products', priceListId, cart, params], () =>
    getPriceListProducts(priceListId ?? '', {
      ...params,
      cart_id: cart?.id,
      region_id: cart?.region_id
    })
  )

  useEffect(() => {
    if (price_list_products != null && cart != null) {
      const productsReview = mapToProductPreview(
        price_list_products.products,
        cart?.region
      )
      setListProductPriceList(productsReview)
    }
  }, [price_list_products, cart])

  return { isLoading, listProductsPriceList, refetch }
}
