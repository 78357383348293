import React, { Fragment } from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'

const SkeletonCart = () => {
  return (
    <Fragment>
      <Flex w={'100%'} gap={2}>
        <Skeleton
          startColor={'bgSkeleton'}
          endColor={'bgSkeleton'}
          h={'100px'}
          flex={1}
        />
        <Skeleton
          startColor={'bgSkeleton'}
          endColor={'bgSkeleton'}
          h={'100px'}
          flex={1}
        />
      </Flex>
      <Skeleton
        startColor={'bgSkeleton'}
        endColor={'bgSkeleton'}
        h={'30px'}
        mt={4}
      />
    </Fragment>
  )
}

export default SkeletonCart
