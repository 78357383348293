import React from 'react'
import { IconPropsType } from './icon-props'

const AddToCartIcon = ({ width = 18, height = 22, color }: IconPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.744 27.484"
    >
      <g
        id="Group_284"
        data-name="Group 284"
        transform="translate(-2541.731 -6234.096)"
      >
        <g id="Group_282" data-name="Group 282">
          <path
            id="Rectangle_311"
            data-name="Rectangle 311"
            d="M0,0H23.244a0,0,0,0,1,0,0V16.179a2.811,2.811,0,0,1-2.811,2.811H2.811A2.811,2.811,0,0,1,0,16.179V0A0,0,0,0,1,0,0Z"
            transform="translate(2542.481 6241.84)"
            fill="none"
            stroke={color ?? 'black'}
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Path_312"
            data-name="Path 312"
            d="M2548.982,6246.683v-6.715a5.136,5.136,0,0,1,5.121-5.122h0a5.137,5.137,0,0,1,5.122,5.122v6.715"
            fill="none"
            stroke={color ?? 'black'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
        <g id="Group_283" data-name="Group 283">
          <line
            id="Line_89"
            data-name="Line 89"
            y2="10.003"
            transform="translate(2554.104 6246.113)"
            fill="none"
            stroke={color ?? 'black'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            id="Line_90"
            data-name="Line 90"
            x2="10.003"
            transform="translate(2549.102 6251.115)"
            fill="none"
            stroke={color ?? 'black'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default AddToCartIcon
