import React, { Fragment, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import ProductMedia, { ImageCustomType } from './product-media'
import ProductSelection from './product-selection'
import { useProductActions } from '@lib/context/product-context'
import CartModal from '@components/modals/cart-modal'
import useProductPrice from '@lib/hooks/use-product-price'
import { useCart, useProduct } from 'medusa-react'
import {
  IProduct,
  IProductImage,
  IProductItem,
  IProductVariant
} from '@common/interface/product'
import DeliveryDate from '@components/delivery-date'
import PreOrderModal from '@components/modals/pre-order-modal'
import { useQuery } from '@tanstack/react-query'
import { listBrandsByCollectionId } from '@lib/util/brand-handler'
import ReviewStarts from './review-starts'
import ProductInfoDetail from './product-info-detail'
import useGA4DataLayer from '@common/hooks/use-ga4-data-layer'
import { mapToProductPreview } from '@lib/hooks/use-layout-data'
import { useReel } from '@lib/context/reel-context'
import { getImagesOfProduct } from '@lib/util/product'

interface PropsType {
  item: IProduct
  isShort?: boolean
}

const Detail = ({ item, isShort }: PropsType) => {
  const [productImages, setProductImages] = useState<ImageCustomType[]>([])

  const { data: brands } = useQuery(['brands', item], () =>
    listBrandsByCollectionId(item?.collection_id ?? '')
  )

  const { viewItemTag } = useGA4DataLayer()

  const {
    updateOptions,
    addToCart,
    variant,
    inStock,
    isPreOrder,
    increaseQuantity,
    decreaseQuantity,
    quantity,
    loading,
    success,
    options
  } = useProductActions()

  const { onClose, onOpen, isOpen } = useDisclosure()
  const {
    onClose: onClosePreOrder,
    onOpen: onOpenPreOrder,
    isOpen: isOpenPreOrder
  } = useDisclosure()
  const { cart } = useCart()

  const price = useProductPrice({ id: item.id, variantId: variant?.id })

  const { product } = useProduct(item?.id)

  const reelHook = useReel()

  const selectedPrice = useMemo(() => {
    const { variantPrice, cheapestPrice } = price

    return variantPrice || cheapestPrice || null
  }, [price, cart])

  useEffect(() => {
    if (cart != null && selectedPrice != null && product != null) {
      const data = mapToProductPreview([product], cart.region)
      viewItemTag(
        cart.region.currency_code,
        parseFloat(
          selectedPrice.calculated_price.match(/(\d|\.)/g)?.join('') as string
        ),
        data
      )
    }
  }, [cart, selectedPrice, product])

  useEffect(() => {
    if (item) {
      const images = getImagesOfProduct(item)
      setProductImages([...images])
    }
  }, [item])

  const optionTooltip = item.options?.map((o) => o.title).join(' , ')

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={{
        md: 10,
        base: 4
      }}
    >
      <PreOrderModal
        isOpen={isOpenPreOrder}
        onCloseHandler={onClosePreOrder}
        product={item as IProductItem}
        pVariantId={variant?.id}
      />
      <CartModal
        isOpen={isOpen}
        onClose={onClose}
        productId={item.id}
        variantId={variant?.id || ''}
      />
      <GridItem
        colSpan={
          isShort
            ? {
                base: 12,
                md: 5
              }
            : {
                base: 12,
                md: 6
              }
        }
      >
        <Box pt={2}>
          {item && (
            <ProductMedia
              images={productImages}
              variantImage={(variant as IProductVariant)?.image}
              productId={item?.id}
              price={price}
            />
          )}
        </Box>
      </GridItem>

      <GridItem
        mt={8}
        colSpan={
          isShort
            ? {
                base: 12,
                md: 7
              }
            : {
                base: 12,
                md: 6
              }
        }
      >
        <Stack gap={5}>
          <Box>
            <Text textStyle="productTitle">{item?.title}</Text>
            {brands && brands.length > 0 && (
              <Text textStyle="productBrandTitle" color={'gray'}>
                {brands[0].title}
              </Text>
            )}

            <ReviewStarts productId={item?.id ?? ''} />
          </Box>

          <Box>
            {selectedPrice ? (
              <Box className="flex flex-col text-gray-700">
                <Text textStyle="productTitle" color={'black'}>
                  {selectedPrice.calculated_price}
                </Text>
                {selectedPrice.price_type === 'sale' && (
                  <>
                    <p>
                      <span className="text-gray-500">Original: </span>
                      <span className="line-through">
                        {selectedPrice.original_price}
                      </span>
                    </p>
                    <span className="text-rose-600">
                      -{selectedPrice.percentage_diff}%
                    </span>
                  </>
                )}
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>

          <Box
            p={isShort ? 4 : 0}
            sx={
              isShort
                ? {
                    height: 140,
                    overflowY: 'auto',
                    border: '1px solid #0000005e',
                    borderRadius: 8
                  }
                : {}
            }
          >
            {item && (
              <ProductInfoDetail
                item={item}
                showBorderBottom={isShort ? false : true}
              />
            )}
          </Box>

          {item && (
            <ProductSelection
              item={item}
              options={options}
              quantity={quantity}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              updateOptions={updateOptions}
              my={isShort ? 0 : 10}
            />
          )}
          <Box>
            <Flex gap={3} mt={5}>
              <Text textStyle="productTitleMediumBold">
                Expected Delivery Date:{' '}
              </Text>
              <DeliveryDate
                delivery_period={item.delivery_period}
                textProps={{
                  fontSize: 'md'
                }}
              />
            </Flex>
          </Box>

          {!inStock && <Text color={'red'}>Out of stock</Text>}
          {inStock && (
            <Tooltip
              hasArrow
              label={`Choose option ${optionTooltip}`}
              bg="black"
              color="white"
              display={!variant ? 'block' : 'none'}
              mt={'6 !important'}
            >
              <Button
                w={'100%'}
                bg={'black'}
                color={'bg'}
                borderRadius={0}
                _hover={{
                  bg: '#171717'
                }}
                onClick={async () => {
                  reelHook.addToCart(item.id)
                  await addToCart()
                  onOpen()
                }}
                isLoading={loading}
                isDisabled={loading || !inStock || !variant}
                size="lg"
              >
                Add to cart
              </Button>
            </Tooltip>
          )}
          {!inStock && isPreOrder && (
            <Button
              py={5}
              mt={'6 !important'}
              w={'100%'}
              bg={'black'}
              color={'bg'}
              borderRadius={0}
              _hover={{
                bg: '#171717'
              }}
              onClick={onOpenPreOrder}
            >
              Pre Order
            </Button>
          )}
          {!inStock && !isPreOrder && (
            <Button
              py={5}
              mt={'6 !important'}
              w={'100%'}
              bg={'gray'}
              color={'bg'}
              borderRadius={0}
              _hover={{
                bg: '#171717'
              }}
              onClick={onOpenPreOrder}
              isDisabled={true}
            >
              Out of stock
            </Button>
          )}
        </Stack>
      </GridItem>
    </Grid>
  )
}

export default Detail
