import React, { Fragment, memo, useMemo } from 'react'
import dynamic from 'next/dynamic'
import { useQuery } from '@tanstack/react-query'
import { listAverageReviews } from '@lib/util/reviews-handler'
import { Flex, Text } from '@chakra-ui/react'
import { StarIconPropsType } from '@icons/star-icon'

const StarIcon = dynamic<StarIconPropsType>(
  () => import('@icons/star-icon').then((mod) => mod.StarIcon),
  {
    ssr: false
  }
)

interface PropsType {
  productId: string
}

const ceilNumber = (input: number) => {
  if (input - parseInt(input.toString()) === 0) {
    return input
  }
  return input - parseInt(input.toString()) > 0.5
    ? parseInt(input.toString()) + 1
    : parseInt(input.toString()) + 0.5
}

const ReviewStarts: React.FC<PropsType> = ({ productId }) => {
  const { data } = useQuery(
    [`list-average-reviews-${productId}`, productId],
    async () => await listAverageReviews(productId)
  )

  const displayStars = useMemo(() => {
    const average = ceilNumber(data?.average ?? 0)

    const arr = Array.from({ length: 5 }, (v: number, k: number) => k + 1)

    return arr.map((v, k) => {
      return (
        <Fragment key={k}>
          {v <= average ? (
            <StarIcon full={true} width={25} height={25} />
          ) : v - average === 0.5 ? (
            <StarIcon mid={true} width={25} height={25} />
          ) : (
            <StarIcon width={25} height={25} />
          )}
        </Fragment>
      )
    })
  }, [data])

  return (
    <Flex gap={4}>
      <Flex gap={2}>{displayStars}</Flex>
      <Text color={'#B6B6B6'} textStyle={'reviewText'} pt={1}>
        {data?.total ?? 0} Reviews
      </Text>
    </Flex>
  )
}

export default memo(ReviewStarts)
