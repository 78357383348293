import React, { createContext, useContext, useEffect, useState } from 'react'
import { useStore } from '../store-context'
import { ProductVariant } from '@medusajs/medusa'
import { useDisclosure } from '@chakra-ui/react'
import ProductDetailModal from './product-detail-modal'
import { useAlert } from '@common/hooks'
import CartModal from '@components/modals/cart-modal'

interface ProductContext {
  isLoading: boolean
  addToCart: () => void
}

const AdditionalCartContext = createContext<ProductContext | null>(null)

interface AdditionalCartProviderProps {
  product: any
  variants: Omit<ProductVariant, 'beforeInsert'>[]
  children?: React.ReactNode
}

export const AdditionalCartProvider = ({
  product,
  variants,
  children
}: AdditionalCartProviderProps) => {
  const { addItem } = useStore()

  const { errorToast } = useAlert()

  const [isLoading, setIsLoading] = useState(false)
  const [variantId, setVariantId] = useState('')

  const {
    isOpen: isOpenProductDetail,
    onOpen: onOpenProductDetail,
    onClose: onCloseProductDetail
  } = useDisclosure()

  const {
    isOpen: isOpenCartModal,
    onOpen: onOpenCartModal,
    onClose: onCloseCartModal
  } = useDisclosure()

  const toggleIsLoading = () => setIsLoading((prev) => !prev)

  const addToCart = async () => {
    try {
      if (variants.length <= 1) {
        toggleIsLoading()

        await addItem({
          variantId: variants[0].id,
          quantity: 1
        })

        setVariantId(variants[0].id)

        onOpenCartModal()

        toggleIsLoading()
      } else {
        onOpenProductDetail()
      }
    } catch (_e) {
      errorToast({
        title: 'Error',
        description: "Can't be added to cart"
      })
    }
  }

  return (
    <AdditionalCartContext.Provider
      value={{
        addToCart,
        isLoading
      }}
    >
      {children}

      <CartModal
        isOpen={isOpenCartModal}
        onClose={onCloseCartModal}
        productId={product.id}
        variantId={variantId || ''}
      />
      <ProductDetailModal
        productHandle={product.handle}
        isOpen={isOpenProductDetail}
        onClose={onCloseProductDetail}
      />
    </AdditionalCartContext.Provider>
  )
}

export const useAdditionalCart = () => {
  const context = useContext(AdditionalCartContext)
  if (context === null) {
    throw new Error(
      'useAdditionalCart must be used within a AdditionalCartProvider'
    )
  }
  return context
}
