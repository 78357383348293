import React, { Fragment, useEffect, useState } from 'react'
import { PriceList } from '@medusajs/medusa'
import { useQuery } from '@tanstack/react-query'
import { getPriceListProducts } from '@lib/util/price-list-handler'
import { convertUTCDateToLocalDate } from '@lib/util/date'
import { Box, Flex, Text } from '@chakra-ui/react'
import Countdown from 'react-countdown'
import FlashsaleBanner from '@images/flashsale-banner.png'
import { useCart } from 'medusa-react'
import { useResponsive } from '@common/hooks'
import { useListPriceList } from '@common/hooks/use-price-list'

interface PropsType {
  productId: string
  isRegionSale?: boolean
}

const DealsDate = ({ date, text }: { date: string; text: string }) => {
  return (
    <Flex w={10} flexDirection={'column'}>
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        h={10}
        bg={'white'}
      >
        <Text textStyle="homeDealDateSmall" textAlign={'center'} mt={0}>
          {date}
        </Text>
      </Flex>
      <Text textAlign={'center'} textStyle={'homeDealTextSmall'}>
        {text}
      </Text>
    </Flex>
  )
}

const ProductFlashSale: React.FC<PropsType> = ({ productId, isRegionSale }) => {
  const [deals, setDeals] = useState<PriceList[]>([])
  const [deal, setDeal] = useState<PriceList | null>(null)
  const [isFlashsale, setIsFlashSale] = useState(false)

  const { listPriceLists } = useListPriceList()

  const { cart } = useCart()

  const { smMax } = useResponsive()

  const { data: price_list_products } = useQuery(
    ['price-list-products', deals, cart?.region],
    () =>
      Promise.all(
        deals.map(async (deal) => await getPriceListProducts(deal.id, {}))
      )
  )

  useEffect(() => {
    if (listPriceLists.length > 0) {
      setDeals(listPriceLists)
    }
  }, [listPriceLists])

  useEffect(() => {
    if (price_list_products != null && price_list_products.length > 0) {
      for (let i = 0; i < price_list_products.length; i++) {
        const psd = price_list_products[i].products.find(
          (p: any) => p.id === productId
        )

        if (psd != null) {
          setIsFlashSale(true)
          setDeal(deals[i])
          break
        }
      }
    }
  }, [price_list_products])

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: any
  }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <Flex gap={2} justifyContent={'end'} h={'auto'}>
          <DealsDate date={`${days as string}`} text="Days" />
          <DealsDate date={`${hours as string}`} text="Hours" />
          <DealsDate date={`${minutes as string}`} text="Minutes" />
          <DealsDate date={`${seconds as string}`} text="Seconds" />
        </Flex>
      )
    }
  }

  return (
    <Fragment>
      {isFlashsale && isRegionSale && deal != null && deal.ends_at ? (
        <Box
          bgImage={FlashsaleBanner ? `url(${FlashsaleBanner.src})` : ''}
          // bgSize="contain"
          bgRepeat="no-repeat"
          // h={'auto'}
          w={'100%'}
          px={0}
          bgPosition="bottom right"
          maxH={'100px'}
        >
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={3}
            py={4}
            px={5}
            w={'100%'}
          >
            <Text
              textStyle={'homeDealTitleMedium'}
              mb={0}
              px={0}
              color={'white'}
            >
              {deal.name}
            </Text>
            <Flex alignItems={'center'} gap={3} pt={'6px'}>
              <Text
                textStyle={'homeDealTitleSmall'}
                mb={5}
                display={smMax ? 'none' : 'block'}
              >
                Ends In
              </Text>
              <Countdown
                date={
                  Date.now() +
                  (new Date(deal.ends_at).getTime() -
                    convertUTCDateToLocalDate(new Date()).getTime())
                }
                renderer={renderer}
              ></Countdown>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default ProductFlashSale
