import { canBuy, canPreOrder } from '@lib/util/can-buy'
import { findCheapestPrice } from '@lib/util/prices'
import isEqual from 'lodash/isEqual'
import { formatVariantPrice, useCart } from 'medusa-react'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Variant } from 'types/medusa'
import { useStore } from './store-context'
import { IProduct } from '@common/interface/product'
import { useRouter } from 'next/router'
import { isEmpty, omit } from 'lodash'
import { ParsedUrlQuery } from 'querystring'
import { useAlert } from '@common/hooks'

interface ProductContext {
  formattedPrice: string
  quantity: number
  disabled: boolean
  inStock: boolean
  isPreOrder: boolean
  variant?: Variant
  maxQuantityMet: boolean
  options: Record<string, string>
  updateOptions: (options: Record<string, string>) => void
  increaseQuantity: () => void
  decreaseQuantity: () => void
  addToCart: () => void
  loading: boolean
  success: boolean
}

const ProductActionContext = createContext<ProductContext | null>(null)

interface ProductProviderProps {
  children?: React.ReactNode
  product: IProduct
}

const getDefaultOptionsInParams = ({
  product,
  params
}: {
  product: IProduct
  params: ParsedUrlQuery
}) => {
  let options: Record<string, string> = {}

  // initialize the option state
  for (const option of product.options) {
    Object.assign(options, { [option.id]: undefined })
  }

  if (!isEmpty(params)) {
    // Loop all params like { Color: 'Black', Size: 'XL' }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_key, value] of Object.entries(params)) {
      // Loop all options of product like Color and Size
      for (const option of product.options) {
        let match = false

        // Example: values for option Color is Black, Pink, Red
        // So if we find 'Pink', we can set match to true to exist loop
        for (const optionValue of option.values) {
          // Check if the param value matches any option value
          if (value === optionValue.value) {
            match = true

            options = { ...options, [option.id]: optionValue.value }
          }
        }

        // If a match is found for this option, break the loop
        if (match) {
          break
        }
      }
    }
  }

  return options
}

export const ProductProvider = ({
  product,
  children
}: ProductProviderProps) => {
  const router = useRouter()

  const { query } = router

  const { errorToast } = useAlert()

  const [quantity, setQuantity] = useState<number>(1)
  const [options, setOptions] = useState<Record<string, string>>({})
  const [maxQuantityMet, setMaxQuantityMet] = useState<boolean>(false)
  const [inStock, setInStock] = useState<boolean>(true)
  const [isPreOrder, setIsPreorder] = useState<boolean>(true)

  const { addItem, loading, success } = useStore()
  const { cart } = useCart()
  const { variants } = product

  useEffect(() => {
    setOptions(
      getDefaultOptionsInParams({
        params: omit(query, 'handle'),
        product
      })
    )
  }, [product, query])

  // memoized record of the product's variants
  const variantRecord = useMemo(() => {
    const map: Record<string, Record<string, string>> = {}

    for (const variant of variants) {
      const tmp: Record<string, string> = {}

      for (const option of variant.options) {
        tmp[option.option_id] = option.value
      }

      map[variant.id] = tmp
    }

    return map
  }, [variants])

  // memoized function to check if the current options are a valid variant
  const variant = useMemo(() => {
    let variantId: string | undefined = undefined

    for (const key of Object.keys(variantRecord)) {
      if (isEqual(variantRecord[key], options)) {
        variantId = key
      }
    }

    const variant = variants.find((v) => v.id === variantId)
    return variant
  }, [options, variantRecord, variants])

  // if product only has one variant, then select it
  useEffect(() => {
    if (variants.length === 1) {
      setOptions(variantRecord[variants[0].id])
    }
  }, [variants, variantRecord])

  const disabled = useMemo(() => {
    return !variant
  }, [variant])

  // memoized function to get the price of the current variant
  const formattedPrice = useMemo(() => {
    if (variant && cart?.region) {
      return formatVariantPrice({ variant, region: cart.region })
    } else if (cart?.region) {
      return findCheapestPrice(variants, cart.region)
    } else {
      // if no variant is selected, or we couldn't find a price for the region/currency
      return 'N/A'
    }
  }, [variant, variants, cart])

  useEffect(() => {
    if (variant) {
      setInStock(canBuy(variant))
      setIsPreorder(canPreOrder(variant))
    }
  }, [variant])

  const updateOptions = (update: Record<string, string>) => {
    const queryParams = { ...router.query, [update.title]: update.value }

    // Sort the query parameters alphabetically by key for consistency
    const sortedQueryParams = Object.keys(queryParams)
      .sort()
      .reduce((sorted: any, key) => {
        sorted[key] = queryParams[key]
        return sorted
      }, {})

    // We only handle router push in product detail page
    if (query.handle) {
      // Shallow routing allows you to change the URL without running data fetching methods again
      // https://nextjs.org/docs/pages/building-your-application/routing/linking-and-navigating#shallow-routing
      router.push(
        {
          pathname: '/products/[handle]',
          query: sortedQueryParams
        },
        undefined,
        { shallow: true }
      )
    }

    const newOption = omit(update, ['title', 'value'])

    setOptions({ ...options, ...newOption })
  }

  const addToCart = async () => {
    try {
      if (variant) {
        await addItem({
          variantId: variant.id,
          quantity
        })
      }
    } catch (e) {
      errorToast({
        title: 'Error',
        description: "Can't added to cart"
      })

      throw e
    }
  }

  const increaseQuantity = () => {
    let maxQuantity = variant?.inventory_quantity || 10

    maxQuantity <= 0 && variant?.allow_backorder ? (maxQuantity = 10) : null

    if (maxQuantity >= quantity + 1) {
      setQuantity(quantity + 1)
    } else {
      setMaxQuantityMet(true)
    }
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)

      if (maxQuantityMet) {
        setMaxQuantityMet(false)
      }
    }
  }

  return (
    <ProductActionContext.Provider
      value={{
        quantity,
        maxQuantityMet,
        disabled,
        inStock,
        isPreOrder,
        options,
        variant,
        addToCart,
        updateOptions,
        decreaseQuantity,
        increaseQuantity,
        formattedPrice,
        loading,
        success
      }}
    >
      {children}
    </ProductActionContext.Provider>
  )
}

export const useProductActions = () => {
  const context = useContext(ProductActionContext)
  if (context === null) {
    throw new Error(
      'useProductActionContext must be used within a ProductActionProvider'
    )
  }
  return context
}
