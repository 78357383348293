import axios from 'axios'
import { BACKEND_URL_STORE } from '@constants/server.constant'
import { isHabbitHostname } from './host'
import qs from 'qs'

export const listBrands = async () => {
  const query: any = {}

  if (isHabbitHostname()) {
    query.is_habbit = true
  } else {
    query.is_jml = true
  }

  const response = await axios.get(`${BACKEND_URL_STORE}/brand`, {
    withCredentials: true,
    params: query
  })

  return response
}

export const getBrand = async (handle: string) => {
  const { data } = await axios.get(
    `${BACKEND_URL_STORE}/brand/handle/${handle}`,
    {
      withCredentials: true
    }
  )

  return data
}

export const listBrandsByCollectionId = async (collectionId: string) => {
  const { data } = await axios.get(
    `${BACKEND_URL_STORE}/brand/collection/${collectionId}`,
    {
      withCredentials: true
    }
  )
  return data
}
