import React from 'react'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { IProduct } from '@common/interface/product'
import parse from 'html-react-parser'

interface PropsType {
  item: IProduct
  showBorderBottom: boolean
}

const ProductInfoDetail: React.FC<PropsType> = ({
  item,
  showBorderBottom = false
}) => {
  return (
    <Stack>
      <Flex borderBottom={'1.5px solid'} pb="14px">
        <Text textStyle="productTitleMedium" fontFamily="ITC">
          Product Detail
        </Text>
      </Flex>
      <Box pt={6} mt="0!" mb={5} textStyle="productDetailSmall">
        {item && parse(item?.description || '')}
      </Box>
      {showBorderBottom && (
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          py={2}
          borderBottom={'1.5px solid'}
        ></Flex>
      )}
    </Stack>
  )
}

export default ProductInfoDetail
