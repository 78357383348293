import React from 'react'
import {
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner
} from '@chakra-ui/react'
import { ProductProvider } from '../product-context'
import { useQuery } from '@tanstack/react-query'
import Detail from '@components/product-detail/detail'
import { fetchProduct } from '@lib/data'

const ProductDetail = ({ productHandle }: { productHandle: string }) => {
  const { isLoading, error, data } = useQuery(
    ['get_product', productHandle],
    () => fetchProduct(productHandle)
  )

  if (isLoading)
    return (
      <Flex justifyContent="center">
        <Spinner size="xl" />
      </Flex>
    )

  if (!data || error) return null

  return (
    <ProductProvider product={data}>
      <Detail isShort item={data} />
    </ProductProvider>
  )
}

const ProductDetailModal = ({
  productHandle,
  isOpen,
  onClose
}: {
  productHandle: string
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxW="min(90%, 1200px)"
        mt={{
          md: 2,
          base: 10
        }}
      >
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody
          py={10}
          px={{
            base: 0
          }}
        >
          <Container>
            <ProductDetail productHandle={productHandle} />
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ProductDetailModal
