import React, { Fragment } from 'react'
import { Product, ProductOption } from '@medusajs/medusa'
import {
  Button,
  Flex,
  Select,
  SimpleGrid,
  Stack,
  StackProps,
  Text
} from '@chakra-ui/react'
import { onlyUnique } from '@lib/util/only-unique'
import { IProduct } from '@common/interface/product'

interface ProductOptionSelectionPropsType {
  option: ProductOption
  updateOptions: (option: Record<string, string>) => void
  options: Record<string, string>
}

const ProductOptionSelection: React.FC<ProductOptionSelectionPropsType> = ({
  option,
  updateOptions,
  options
}) => {
  const filteredOptions =
    option?.values?.map((v) => v.value).filter(onlyUnique) ?? []

  const displayOption = () => {
    return filteredOptions?.map((v) => {
      return (
        <option key={v} value={v}>
          {v}
        </option>
      )
    })
  }

  return (
    <Fragment>
      <Select
        w={'auto'}
        border={'0px'}
        p={0}
        _focus={{
          boxShadow: '0px 0px 0px 0px'
        }}
        value={options[option.id]}
        onChange={(e) => {
          updateOptions({
            [option.id]: e.target.value,
            title: option.title,
            value: e.target.value
          })
        }}
        defaultValue={'choose'}
        textStyle={'selectionOptionText'}
      >
        <option value={'choose'} disabled={true}>
          Choose {option.title}
        </option>
        {displayOption()}
      </Select>
    </Fragment>
  )
}

interface PropsType extends StackProps {
  item: IProduct
  quantity: number
  options: Record<string, string>
  increaseQuantity: () => void
  decreaseQuantity: () => void
  updateOptions: (options: Record<string, string>) => void
}

const ProductSelection: React.FC<PropsType> = ({
  item,
  options,
  increaseQuantity,
  decreaseQuantity,
  updateOptions,
  quantity,
  ...rest
}) => {
  return (
    <Stack gap={2} my={10} {...rest}>
      <Text textStyle="productTitleMediumBold">Selection</Text>
      <SimpleGrid
        templateColumns={{
          sm: '1fr',
          xl: '6fr 6fr'
        }}
        spacing={7}
      >
        {item.options?.length > 0 &&
          item.options.map((option: any) => {
            return (
              <Flex
                key={option.id}
                py={2}
                borderBottom={'1.5px solid'}
                flex={1}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Text textStyle="productTitleMedium">{option.title}</Text>
                <ProductOptionSelection
                  key={option.id}
                  option={option}
                  updateOptions={updateOptions}
                  options={options}
                />
              </Flex>
            )
          })}
        <Flex
          h={14}
          py={2}
          borderBottom={'1.5px solid'}
          flex={1}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text textStyle="productTitleMedium" fontFamily="ITC">
            Quantity
          </Text>
          <Flex alignItems={'center'} justifyContent="flex-end">
            <Button
              w={0}
              h={0}
              textStyle="productTitleExtraSmall"
              onClick={decreaseQuantity}
              bg="transparent"
              _hover={{
                bg: 'transparent'
              }}
            >
              -
            </Button>
            <Text textStyle="productTitleExtraSmall" w={6} textAlign={'center'}>
              {quantity}
            </Text>
            <Button
              w={0}
              h={0}
              textStyle="productTitleExtraSmall"
              onClick={increaseQuantity}
              bg="transparent"
              _hover={{
                bg: 'transparent'
              }}
            >
              +
            </Button>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Stack>
  )
}

export default ProductSelection
