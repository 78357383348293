import React, { Fragment, useEffect, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  Box
} from '@chakra-ui/react'
import { useCart } from 'medusa-react'
import useEnrichedLineItems from '@lib/hooks/use-enrich-line-items'
import Thumbnail from '@modules/products/components/thumbnail'
import Link from 'next/link'
import LineItemOptions from '@modules/common/components/line-item-options'
import LineItemPrice from '@modules/common/components/line-item-price'
import { useStore } from '@lib/context/store-context'
import SkeletonCart from '@components/skeleton/skeleton-cart'

interface PropsType {
  isOpen: boolean
  onClose: () => void
  productId: string
  variantId: string
}

const CartModalItem = ({
  cart,
  item,
  onCloseHandler
}: {
  cart: any
  item: any
  onCloseHandler: () => void
}) => {
  return (
    <Fragment>
      <Box display="flex" gap={4} flexWrap="wrap" key={item.id}>
        <Box width="122px" margin="0 auto">
          <Thumbnail
            thumbnail={item?.variant?.image ?? item.thumbnail}
            size="full"
          />
        </Box>
        <Box className="flex flex-row justify-between flex-1 flex-wrap gap-2">
          <Box
            width={{
              sm: '50%',
              base: '100%'
            }}
          >
            <h3 className="text-base-regular overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[230px]">
              {item.title}
            </h3>
            <LineItemOptions variant={item.variant} />
            <span>Quantity: {item.quantity}</span>
          </Box>
          <LineItemPrice region={cart.region} item={item} style="tight" />
        </Box>
      </Box>
      <Link href="/cart" passHref>
        <Button
          py={5}
          mt={'6 !important'}
          w={'100%'}
          bg={'black'}
          color={'bg'}
          borderRadius={0}
          _hover={{
            bg: '#171717'
          }}
          onClick={onCloseHandler}
        >
          Go to bag
        </Button>
      </Link>
    </Fragment>
  )
}

const CartModal = ({ isOpen, onClose, productId, variantId }: PropsType) => {
  const { cart } = useCart()
  const { setSuccess, loading } = useStore()
  const items = useEnrichedLineItems()
  const [item, setItem] = useState<any>(null)

  useEffect(() => {
    if (items && items.length > 0) {
      const findItem = items.find(
        (item) =>
          item.variant.product_id === productId && item.variant.id === variantId
      )
      setItem(findItem)
    }
  }, [items])

  useEffect(() => {
    if (loading) {
      setItem(null)
    }
  }, [loading])

  const onCloseHandler = () => {
    setSuccess(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay zIndex={2000} />
      <ModalContent
        maxW="min(90%, 448px)"
        containerProps={{
          zIndex: 2000
        }}
      >
        <ModalHeader fontFamily="ITC">Shopping Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={5}>
          {!item && <SkeletonCart />}
          {cart && item && (
            <CartModalItem
              cart={cart}
              item={item}
              onCloseHandler={onCloseHandler}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CartModal
