export const convertToCloudFrontImage = (image: string) => {
  // if (
  //   process.env.NEXT_PUBLIC_CLOUD_FRONT != null &&
  //   (process.env.NEXT_PUBLIC_CLOUD_FRONT as string).length > 0
  // ) {
  //   const imageSplit = image.split('/')
  //   const nameImage = imageSplit[imageSplit.length - 1]
  //
  //   return `${process.env.NEXT_PUBLIC_CLOUD_FRONT as string}/${nameImage}`
  // }
  return image
}
