import axios from 'axios'
import { BACKEND_URL_STORE } from '@constants/server.constant'
import { StoreGetProductsParams } from '@medusajs/medusa'

export interface ListProductsPriceListParams extends StoreGetProductsParams {
  cart_id?: string
  region_id?: string
  price_list_id?: string[]
}

export const listPriceList = () => axios.get(`${BACKEND_URL_STORE}/price-lists`)

export const getPriceList = async (id: string) => {
  const { data } = await axios.get(`${BACKEND_URL_STORE}/price-lists/${id}`)
  return data
}

export const getPriceListProducts = async (
  id: string,
  params: ListProductsPriceListParams
) => {
  const { data } = await axios.get(
    `${BACKEND_URL_STORE}/price-lists/${id}/products`,
    {
      params: {
        ...params
      }
    }
  )
  return data
}
