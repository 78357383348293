import React from 'react'
import { Box, Button, Flex, Stack } from '@chakra-ui/react'
import { IProductItem, IProductVariant } from '@common/interface/product'
import Image from 'next/image'
import { Variant } from 'types/medusa'
import { canBuy } from '@lib/util/can-buy'

interface ProductPreviewProps {
  product: IProductItem
  productVariantId: string | null
  setProductVariantId: (productVariantId: string | null) => void
}

const ProductVariantOutofstock = ({
  variants,
  setProductVariantId,
  productVariantId
}: {
  variants: Variant[]
  setProductVariantId: (productVariantId: string | null) => void
  productVariantId: string | null
}) => {
  const variantsOutOfStock = variants.filter((variant) => !canBuy(variant))

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={3}
      flexWrap={'wrap'}
    >
      {variantsOutOfStock.map((variant) => {
        return (
          <Button
            mt={0}
            key={variant.id}
            bg={productVariantId === variant.id ? 'black' : 'white'}
            color={productVariantId === variant.id ? 'white' : 'black'}
            borderRadius={0}
            variant={productVariantId === variant.id ? 'solid' : 'outline'}
            onClick={() => {
              setProductVariantId(variant.id)
            }}
            _hover={{
              bg: 'black',
              color: 'white'
            }}
          >
            {variant.title}
          </Button>
        )
      })}
    </Box>
  )
}

const ProductPreviewItem: React.FC<ProductPreviewProps> = ({
  product,
  productVariantId,
  setProductVariantId
}) => {
  const variant = product.variants.find((v) => v.id === productVariantId)

  return (
    <Stack gap={5}>
      <Flex justifyContent={'center'}>
        <Image
          src={
            variant !== undefined && (variant as IProductVariant).image
              ? ((variant as IProductVariant).image as string)
              : product.thumbnail ?? ''
          }
          alt={product.title}
          width={365}
          height={424}
          quality={100}
          style={{
            width: '365px',
            maxHeight: '424px'
          }}
          priority={true}
        />
      </Flex>
      <ProductVariantOutofstock
        variants={product.variants}
        setProductVariantId={setProductVariantId}
        productVariantId={productVariantId}
      />
    </Stack>
  )
}

export default ProductPreviewItem
