export const LIST_REVIEWS = (productId: string) =>
  `/store/products/${productId}/reviews`

export const LIST_AVERAGE_REVIEWS = (productId: string) =>
  `/store/products/${productId}/average-reviews`

export const GET_ORDER_REVIEW = (orderId: string) =>
  `/store/orders/${orderId}/reviews`

export const CREATE_REVIEW = (productId: string) =>
  `/store/products/${productId}/reviews`

export const UPLOAD_REVIEW_MEDIAS = (productId: string) =>
  `/store/products/${productId}/reviews/upload-medias`
