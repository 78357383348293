import {
  IProduct,
  IProductImage,
  IProductVariant
} from '@common/interface/product'
import { ImageCustomType } from '@components/product-detail/product-media'

// Including image of variant
export const getImagesOfProduct = (product: IProduct) => {
  const images: ImageCustomType[] = []

  if (product.thumbnail) {
    images.push({
      id: 'thumbnail',
      url: product.thumbnail,
      media_type: 'image'
    })
  }

  for (let i = 0; i < product.images?.length || 0; i++) {
    const image = product.images[i] as IProductImage
    if (image?.media_type && image?.media_type === 'image') {
      images.push({
        id: 'media' + image.id,
        url: image.url || '',
        media_type: image?.media_type || 'image'
      })
    }
  }

  for (let i = 0; i < product.variants?.length || 0; i++) {
    const variant = product.variants[i] as IProductVariant

    if (variant?.image) {
      images.push({
        id: 'media' + variant.id,
        url: variant?.image || '',
        media_type: 'image'
      })
    }
  }

  return images
}
