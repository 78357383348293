import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { IProductItem } from '@common/interface/product'
import ProductPreviewItem from '@components/product-preview-item'
import { useAlert, useResponsive } from '@common/hooks'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { useMutation } from '@tanstack/react-query'
import { createPreOrder } from '@lib/util/pre-order-handler'

interface PreOrderInput {
  full_name: string
  email: string
  quantity: number
}

interface PreOrderModalProps {
  isOpen: boolean
  onCloseHandler: () => void
  product: IProductItem
  pVariantId?: string
}

const PreOrderModal = ({
  isOpen,
  onCloseHandler,
  product,
  pVariantId
}: PreOrderModalProps) => {
  const [productVariantId, setProductVariantId] = useState<string | null>(
    pVariantId ?? null
  )

  const [contactNumber, setContactNumber] = useState<string | null>(null)

  const { mutate, isLoading } = useMutation(createPreOrder)

  const { register, handleSubmit, reset } = useForm<PreOrderInput>()

  const alert = useAlert()

  const { smMax } = useResponsive()

  const onCloseModal = () => {
    reset({
      full_name: '',
      email: '',
      quantity: 0
    })
    setContactNumber(null)
    setProductVariantId(null)
    onCloseHandler()
  }

  const onSubmitHandler = (data: PreOrderInput) => {
    const preOrderInput = {
      ...data,
      quantity: parseInt(data.quantity.toString()),
      contact_number: contactNumber,
      product_variant_id: productVariantId ?? pVariantId
    }

    mutate(preOrderInput, {
      onSuccess() {
        reset({
          full_name: '',
          email: '',
          quantity: 0
        })
        setContactNumber(null)
        setProductVariantId(null)
        alert.successToast({
          description: 'Pre Order Successfully!'
        })
        onCloseHandler()
      },
      onError(error: any) {
        alert.errorToast({
          description: error.message
        })
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pre Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={5}>
          <Flex w={'100%'} justifyContent={'center'}>
            <Box w={'100%'}>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Flex justifyContent={'center'} w={'100%'}>
                  <Flex
                    gap={10}
                    flexDirection={smMax ? 'column' : 'row'}
                    w={'80%'}
                  >
                    <Flex flex={2}>
                      <Stack spacing={5} style={{ width: '100%' }}>
                        <Box>
                          <FormLabel>Fullname</FormLabel>
                          <Input
                            placeholder="Full name"
                            {...register('full_name', { required: true })}
                            _focus={{
                              border: '2px solid black',
                              shadow: 'none'
                            }}
                          />
                        </Box>
                        <Box>
                          <FormLabel>Email</FormLabel>
                          <Input
                            type="email"
                            placeholder="Email"
                            {...register('email', { required: true })}
                            _focus={{
                              border: '2px solid black',
                              shadow: 'none'
                            }}
                          />
                        </Box>
                        <Box>
                          <FormLabel mb={5}>Contact Number</FormLabel>
                          <PhoneInput
                            inputStyle={{ border: '1px solid gray' }}
                            dropdownStyle={{ zIndex: 1 }}
                            country={'sg'}
                            value={contactNumber}
                            onChange={(phone) => {
                              setContactNumber(phone)
                            }}
                            enableSearch
                          />
                        </Box>
                        <Box>
                          <FormLabel>Quantity</FormLabel>
                          <Input
                            zIndex={0}
                            type="number"
                            placeholder="0"
                            {...register('quantity', { required: true })}
                            _focus={{
                              border: '2px solid black',
                              shadow: 'none'
                            }}
                          />
                        </Box>
                        {!smMax && (
                          <Button
                            type="submit"
                            py={5}
                            mt={'6 !important'}
                            w={'100%'}
                            bg={'black'}
                            color={'bg'}
                            borderRadius={0}
                            _hover={{
                              bg: '#171717'
                            }}
                            isDisabled={
                              (!productVariantId && !pVariantId) || isLoading
                            }
                            isLoading={isLoading}
                          >
                            Send
                          </Button>
                        )}
                      </Stack>
                    </Flex>
                    <Flex flex={2} justifyContent={'center'}>
                      <Box>
                        <ProductPreviewItem
                          product={product}
                          productVariantId={
                            productVariantId ?? pVariantId ?? null
                          }
                          setProductVariantId={(
                            productVariantId: string | null
                          ) => {
                            setProductVariantId(productVariantId)
                          }}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                {smMax && (
                  <Button
                    type="submit"
                    py={5}
                    mt={'6 !important'}
                    w={'100%'}
                    bg={'black'}
                    color={'bg'}
                    borderRadius={0}
                    _hover={{
                      bg: '#171717'
                    }}
                    isDisabled={(!productVariantId && !pVariantId) || isLoading}
                    isLoading={isLoading}
                  >
                    Send
                  </Button>
                )}
              </form>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PreOrderModal
