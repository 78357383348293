export const PRODUCT_LIMIT = 4
export const SHOP_PRODUCT_LIMIT = 20
export const PRODUCT_SANITY_NAME = 'product'

export const ITEMS_PER_PAGE = {
  xl: 4,
  lg: 3,
  md: 3,
  sm: 2,
  base: 2
}
